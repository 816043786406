import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import { Upload } from 'src/app/core/models/uploads/upload';
import { UploadType } from 'src/app/core/models/uploads/upload-type';
import { PaginatedResult } from 'src/app/core/models/wrappers/PaginatedResult';
import { Category } from '../../../models/category';
import { CategoryParams } from '../../../models/categoryParams';
import {Item} from '../../../models/item';
import { CategoryService } from '../../../services/category.service';
import {ItemService} from '../../../services/item.service';
import {environment} from 'src/environments/environment';

@Component({
  selector: 'app-item-form',
  templateUrl: './item-form.component.html',
  styleUrls: ['./item-form.component.scss']
})
export class ItemFormComponent implements OnInit {
  itemForm: UntypedFormGroup;
  formTitle: string;
  categories: PaginatedResult<Category>;
  categoryParams = new CategoryParams();

  url: any = [];
  upload = new Upload();

  constructor(@Inject(MAT_DIALOG_DATA) public data: Item, private itemService: ItemService,  private categoryService: CategoryService,
 private toastr: ToastrService, private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.categoryParams.pageSize = 50;
    this.getCategories();
    this.loadItemImage();
  }

  initializeForm() {
    this.itemForm = this.fb.group({
      id: [this.data && this.data.id],
      name: [this.data && this.data.name, Validators.required],
      categoryId: [this.data && this.data.categoryId, Validators.required], // todo get categories and show dropdown list to select category
      detail: [this.data && this.data.detail, Validators.required]
    });
    if (this.itemForm.get('id').value === '' || this.itemForm.get('id').value == null) {
      this.formTitle = 'Register Item';
    } else {
      this.formTitle = 'Edit Item';
    }
  }

  getCategories() {
    this.categoryService.getCategories(this.categoryParams).subscribe((response) => { this.categories = response; });
  }

  loadItemImage() {
    this.itemService.getItemImageById(this.data.id).subscribe((response) => {
      if(response.data!='')
      this.url = environment.imageBaseUrl + response.data;
    });
  }

  onSelectFile($event) {
    this.upload = $event;
    this.upload.uploadType = UploadType.Item;
  }

  onSubmit() {
    // TODO after successful update/insert, refresh table view in component product.component.ts
    if (this.itemForm.valid) {
      if (this.itemForm.get('id').value === '' || this.itemForm.get('id').value == null) {
        this.itemService.createItem(this.itemForm.value, this.upload).subscribe(response => {
          this.toastr.success(response.messages[0]);
        });
      } else {
        this.itemService.updateItem(this.itemForm.value, this.upload).subscribe(response => {
          this.toastr.success(response.messages[0]);
        });
      }
    }
  }

}
