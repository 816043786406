import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Item } from 'src/app/modules/admin/catalog/models/item';
import {environment} from 'src/environments/environment';
import { Result } from '../../models/wrappers/Result';

@Injectable()
export class ItemApiService {

  baseUrl = environment.apiUrl + 'catalog/items/';

  constructor(private http: HttpClient) {
  }

  getAlls(params: HttpParams) {
    return this.http.get(this.baseUrl, {params: params});
  }

  getById(id: string) {
    return this.http.get<Result<Item>>(this.baseUrl + id);
  }

  getImageById(id: string) {
    return this.http.get(this.baseUrl + `image/${id}`);
  }

  create(item: Item) {
    return this.http.post(this.baseUrl+"register", item);
  }

  update(item: Item) {
    return this.http.post(this.baseUrl+"update", item);
  }

  delete(id: string) {
    return this.http.delete(this.baseUrl + id);
  }
}
