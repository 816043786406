import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UomApiService } from 'src/app/core/api/catalog/uom-api.service';
import { IResult } from 'src/app/core/models/wrappers/IResult';
import { PaginatedResult } from 'src/app/core/models/wrappers/PaginatedResult';
import { Uom } from '../models/uom';
import { UomParams } from '../models/uomParams';

@Injectable()
export class UomService {
  constructor(private api: UomApiService) {}

  getUoms(uomParams: UomParams): Observable<PaginatedResult<Uom>> {
    let params = new HttpParams();
    if (uomParams.searchString)
      params = params.append('searchString', uomParams.searchString);
    if (uomParams.pageNumber)
      params = params.append('pageNumber', uomParams.pageNumber.toString());
    if (uomParams.pageSize)
      params = params.append('pageSize', uomParams.pageSize.toString());
    if (uomParams.orderBy)
      params = params.append('orderBy', uomParams.orderBy.toString());
    return this.api
      .getAlls(params)
      .pipe(map((response: PaginatedResult<Uom>) => response));
  }

  getUomById(id: string): Observable<Uom> {
    return this.api.getById(id).pipe(map((response: Uom) => response));
  }

  createUom(uom: Uom): Observable<IResult<Uom>> {
    return this.api
      .create(uom)
      .pipe(map((response: IResult<Uom>) => response));
  }

  updateUom(uom: Uom): Observable<IResult<Uom>> {
    return this.api
      .update(uom)
      .pipe(map((response: IResult<Uom>) => response));
  }

  deleteUom(id: string): Observable<IResult<string>> {
    return this.api
      .delete(id)
      .pipe(map((response: IResult<string>) => response));
  }
}
