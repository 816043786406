<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="inputSideNav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <a mat-button routerLink="/" aria-label="F2h-POS">
      <img class="angular-logo" src="/assets/angular-white-transparent.svg" alt="Angular Logo White">
      <span style="font-weight:lighter;font-size: larger;" translate>site.title</span>
    </a>
    <span class="nav-spacer"></span>

    <!-- <a mat-icon-button matTooltip="{{'site.fork'|translate}}" href="#"
      target="_blank" class="d-none d-sm-block">
      <mat-icon class="fab fa-github"></mat-icon>
    </a> -->

    <a mat-icon-button (click)="toggleDarkMode()" class="d-none d-sm-block">
      <mat-icon>{{darkModeIcon}}</mat-icon>
    </a>
    <mat-divider vertical style="height:50%;margin: 10px;"></mat-divider>

    <!-- <a mat-raised-button color="primary" style="margin-right: 5px;" href="#" target="_blank"
      class="d-none d-sm-block">
      <span style="font-weight:lighter;font-size: small;" translate><span style="margin-right: 10px;" class="fas fa-info"></span> site.support</span>
    </a> -->
    <button mat-raised-button routerLink="/pos" color="primary" class="d-none d-lg-block"><span style="margin-right: 10px;" class="fas fa-cash-register"></span>POS</button>
    <button mat-raised-button color="accent" [matMenuTriggerFor]="userMenu">
      <span><span style="margin-right: 10px;" class="fas fa-caret-down"></span>{{fullName}}</span>
    </button>
    <button mat-raised-button  color="accent" class="d-sm-none"><span style="margin-right: 10px;" class="fas fa-caret-down"> </span>More</button>
    <mat-menu #userMenu="matMenu" style="padding-top: 0!important;">
      <mat-card style="padding-top: 16px!important;margin-bottom: 0px!important;">
        <mat-card-header>
          <div mat-card-avatar class="example-header-image"></div>
          <mat-card-title style="margin-bottom: 10px;font-size:medium">{{fullName}}</mat-card-title>
          <mat-card-subtitle style="margin-bottom: 5px;font-size:small">{{email}}</mat-card-subtitle>
        </mat-card-header>
      </mat-card>
      <button mat-menu-item translate routerLink="/admin/identity/profile">userCard.profile</button>
      <button mat-menu-item routerLink="/admin/settings" translate>userCard.settings</button>
      <mat-divider></mat-divider>
      <button mat-menu-item (click)="openLogoutDialog()" translate>userCard.logout</button>
    </mat-menu>
  </mat-toolbar-row>
</mat-toolbar>
