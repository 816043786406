import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { Uom } from 'src/app/modules/admin/catalog/models/uom';
import {environment} from 'src/environments/environment';

@Injectable()
export class UomApiService {

  baseUrl = environment.apiUrl + 'catalog/uoms/';

  constructor(private http: HttpClient) {
  }

  getAlls(params: HttpParams) {
    return this.http.get(this.baseUrl, {params: params});
  }

  getById(id: string) {
    return this.http.get<Uom>(this.baseUrl + id);
  }

  create(uom: Uom) {
    return this.http.post(this.baseUrl+"register", uom);
  }

  update(uom: Uom) {
    return this.http.post(this.baseUrl+"update", uom);
  }

  delete(id: string) {
    return this.http.delete(this.baseUrl + id);
  }
}
