import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaginatedFilter } from 'src/app/core/models/Filters/PaginatedFilter';
import { PaginatedResult } from 'src/app/core/models/wrappers/PaginatedResult';
import { Item } from '../../models/item';
import { ItemParams } from '../../models/itemParams';
import { ItemService } from '../../services/item.service';
import { ItemFormComponent } from './item-form/item-form.component';
import { ToastrService } from 'ngx-toastr';
import { Sort } from '@angular/material/sort';
import { TableColumn } from 'src/app/core/shared/components/table/table-column';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss'],
})
export class ItemComponent implements OnInit {
  items: PaginatedResult<Item>;
  itemColumns: TableColumn[];
  itemParams = new ItemParams();
  searchString: string;

  constructor(
    public itemService: ItemService,
    public dialog: MatDialog,
    public toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.getItems();
    this.initColumns();
  }

  getItems(): void {
    this.itemService.getItems(this.itemParams).subscribe((result) => {
      this.items = result;
    });
  }

  initColumns(): void {
    this.itemColumns = [
      //{ name: 'Id', dataKey: 'id', isSortable: true, isShowable: true },
      { name: 'Name', dataKey: 'name', isSortable: true, isShowable: true },
      { name: 'CategoryName', dataKey: 'categoryName', isSortable: true, isShowable: true },
      { name: 'Detail', dataKey: 'detail', isSortable: true, isShowable: true },
      { name: 'Action', dataKey: 'action', position: 'right' },
    ];
  }

  pageChanged(event: PaginatedFilter): void {
    this.itemParams.pageNumber = event.pageNumber;
    this.itemParams.pageSize = event.pageSize;
    this.getItems();
  }

  openForm(item?: Item): void {
    console.log(item);
    const dialogRef = this.dialog.open(ItemFormComponent, {
      data: item,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result===true) {
        this.getItems();
      }
    });
  }

  remove($event: string): void {
    this.itemService.deleteItem($event).subscribe(() => {
      this.getItems();
      this.toastr.info('Item Removed');
    });
  }

  sort($event: Sort): void {
    this.itemParams.orderBy = $event.active + ' ' + $event.direction;
    this.getItems();
  }

  filter($event: string): void {
    this.itemParams.searchString = $event.trim().toLocaleLowerCase();
    this.itemParams.pageNumber = 0;
    this.itemParams.pageSize = 0;
    this.getItems();
  }

  reload(): void {
    this.itemParams.searchString = '';
    this.itemParams.pageNumber = 0;
    this.itemParams.pageSize = 0;
    this.getItems();
  }
}
