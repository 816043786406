<div *ngIf="!this.brands || !this.items" class="d-flex justify-center p-4">
  <mat-icon>
    <mat-spinner diameter="20">
    </mat-spinner>
  </mat-icon> Loading ...
</div>
<div *ngIf="this.brands && this.items">
  <h2 mat-dialog-title>{{formTitle}}</h2>
  <form (ngSubmit)="onSubmit()" [formGroup]="productForm">
    <mat-dialog-content class="mat-typography">
      <div class="row">
        <input formControlName="id" type="hidden">
        <div class="col-xs-12 col-md-3">
            <app-uploader [url]="this.url" (onLoadFile)="onSelectFile($event)"></app-uploader>
        </div>
        <div class="col-xs-12 col-md-9">
  <div class="row">
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input autocomplete="off" formControlName="name" matInput placeholder="Name" required>
        <mat-error>Please provide a valid name</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Locale Name</mat-label>
        <input autocomplete="off" formControlName="localeName" matInput placeholder="Locale Name" required>
        <mat-error>Please provide a valid locale name</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Cost</mat-label>
        <input autocomplete="off" formControlName="cost" matInput placeholder="Cost" required type="number">
        <mat-error>Please provide a valid Cost value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Selling Price</mat-label>
        <input autocomplete="off" formControlName="sellingPrice" matInput placeholder="Selling Price" required type="number">
        <mat-error>Please provide a valid Selling Price value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Mfd Price</mat-label>
        <input autocomplete="off" formControlName="mfdPrice" matInput placeholder="Mfd Price" required type="number">
        <mat-error>Please provide a valid Mfd Price value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Wholesale Price</mat-label>
        <input autocomplete="off" formControlName="wholeSalePrice" matInput placeholder="Wholesale Price" required type="number">
        <mat-error>Please provide a valid Wholesale Price value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Export Price</mat-label>
        <input autocomplete="off" formControlName="exportPrice" matInput placeholder="Export Price" required type="number">
        <mat-error>Please provide a valid Selling Export value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Online Price</mat-label>
        <input autocomplete="off" formControlName="onlinePrice" matInput placeholder="Online Price" required type="number">
        <mat-error>Please provide a valid Selling Online value</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline" *ngIf="brands && brands.data">
        <mat-select placeholder="Select Brand" [(value)]="data && data.brandId" formControlName="brandId">
          <mat-option *ngFor="let brand of brands.data" [value]="brand.id">
            {{ brand.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline" *ngIf="items && items.data">
        <mat-select placeholder="Select Item" [(value)]="data && data.item" formControlName="itemId">
          <mat-option *ngFor="let item of items.data" [value]="item.id">
            {{ item.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Weight</mat-label>
        <input autocomplete="off" formControlName="weight" matInput placeholder="Weight" required type="number">
        <mat-error>Please provide a valid Weight</mat-error>
      </mat-form-field>
    </div>

    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline" *ngIf="uoms && uoms.data">
        <mat-select placeholder="Select UOM" [(value)]="data && data.uomId" formControlName="uomId">
          <mat-option *ngFor="let uom of uoms.data" [value]="uom.id">
            {{ uom.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Tax</mat-label>
        <input autocomplete="off" formControlName="tax" matInput placeholder="Tax" required type="number">
        <mat-error>Please provide a valid Tax</mat-error>
      </mat-form-field>
    </div>

    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Tax Method</mat-label>
        <input autocomplete="off" formControlName="taxMethod" matInput placeholder="Tax Method" required>
        <mat-error>Please provide a valid Tax Method</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <div class="row">
      <div class="col-md-3">
        <mat-checkbox color="primary" formControlName="isAlert" id="isAlert">Alert Active</mat-checkbox>
      </div>
      <div class="col-md-1"></div>
      <div class="col-md-8">
        <mat-form-field appearance="outline">
          <mat-label>Alert Quantity</mat-label>
          <input autocomplete="off" formControlName="alertQuantity" matInput placeholder="Alert Quantity" required
                 type="number">
          <mat-error>Please provide a valid Quantity</mat-error>
        </mat-form-field>
      </div>
    </div>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Barcode Symbology</mat-label>
        <input autocomplete="off" formControlName="barcodeSymbology" matInput placeholder="Barcode Symbology" required>
        <mat-error>Please provide a valid Barcode</mat-error>
      </mat-form-field>
    </div>
    <div class="col-xs-12 col-md-6">
      <mat-form-field appearance="outline">
        <mat-label>Detail</mat-label>
        <input autocomplete="off" formControlName="detail" matInput placeholder="Detail">
        <mat-error>
          Please provide a valid detail
        </mat-error>
      </mat-form-field>
    </div>
  </div>
        </div>

      </div>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-dialog-close mat-raised-button>Cancel</button>
      <button [disabled]="!productForm.dirty || !productForm.valid" [mat-dialog-close]="true" cdkFocusInitial color="primary"
              mat-raised-button type="submit">Save
      </button>
    </mat-dialog-actions>
  </form>
</div>
