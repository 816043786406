
<div *ngIf="!this.categories" class="d-flex justify-center p-4">
  <mat-icon>
    <mat-spinner diameter="20">
    </mat-spinner>
  </mat-icon> Loading ...
</div>
<div *ngIf="this.categories">
<h2 mat-dialog-title>{{formTitle}}</h2>
<form [formGroup]="itemForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content class="mat-typography">
    <div class="row">
    <input mat type="hidden" formControlName="id">
    <input mat type="hidden" formControlName="imageUrl">
    <div class="col-xs-12 col-md-3">
      <app-uploader [url]="this.url" (onLoadFile)="onSelectFile($event)"></app-uploader>
  </div>
  <div class="col-xs-12 col-md-9">
    <div class="row">
    <mat-form-field appearance="outline">
      <mat-label>Name</mat-label>
      <input matInput autocomplete="off" placeholder="Name" formControlName="name" required>
      <mat-error>
        Please provide a valid name
      </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" *ngIf="categories && categories.data">
      <mat-select placeholder="Select Category" [(value)]="data && data.categoryId" formControlName="categoryId">
        <mat-option *ngFor="let category of categories.data" [value]="category.id">
          {{ category.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <p></p>
    <mat-form-field appearance="outline">
      <mat-label>Detail</mat-label>
      <input matInput autocomplete="off" placeholder="Detail" formControlName="detail">
      <mat-error>
        Please provide a valid detail
      </mat-error>
    </mat-form-field>
  </div>
  </div>
</div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>Cancel</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial color="primary"
      [disabled]="!itemForm.dirty || !itemForm.valid" type="submit">Save</button>
  </mat-dialog-actions>
</form>
</div>
