import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ItemApiService } from 'src/app/core/api/catalog/item-api.service';
import { Upload } from 'src/app/core/models/uploads/upload';
import { IResult } from 'src/app/core/models/wrappers/IResult';
import { PaginatedResult } from 'src/app/core/models/wrappers/PaginatedResult';
import { Result } from 'src/app/core/models/wrappers/Result';
import { Item } from '../models/item';
import { ItemParams } from '../models/itemParams';

@Injectable()
export class ItemService {
  constructor(private api: ItemApiService) {}

  getItems(
    itemParams: ItemParams
  ): Observable<PaginatedResult<Item>> {
    let params = new HttpParams();
    if (itemParams.searchString) {
      params = params.append('searchString', itemParams.searchString);
    }
    if (itemParams.pageNumber) {
      params = params.append('pageNumber', itemParams.pageNumber.toString());
    }
    if (itemParams.pageSize) {
      params = params.append('pageSize', itemParams.pageSize.toString());
    }
    if (itemParams.orderBy) {
      params = params.append('orderBy', itemParams.orderBy.toString());
    }
    return this.api
      .getAlls(params)
      .pipe(map((response: PaginatedResult<Item>) => response));
  }

  getItemById(id: string): Observable<Result<Item>> {
    return this.api.getById(id).pipe(map((response: Result<Item>) => response));
  }

  getItemImageById(id: string): Observable<Result<string>> {
    return this.api.getImageById(id).pipe(map((response: Result<string>) => response));
  }

  createItem(item: Item, upload: Upload): Observable<IResult<Item>> {
    if (upload != undefined && upload.data != undefined) item.uploadRequest = upload;
    return this.api
      .create(item)
      .pipe(map((response: IResult<Item>) => response));
  }

  updateItem(item: Item, upload: Upload): Observable<IResult<Item>> {
    if (upload != undefined && upload.data != undefined) item.uploadRequest = upload;
    return this.api
      .update(item)
      .pipe(map((response: IResult<Item>) => response));
  }

  deleteItem(id: string): Observable<IResult<string>> {
    return this.api
      .delete(id)
      .pipe(map((response: IResult<string>) => response));
  }
}
